import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

import { useEffect } from 'react';
import gsap from "gsap";

// styles
import "../styles/global.css";
import "../styles/about.css";
import "@fontsource/sora/300.css";
import "@fontsource/sora/500.css";
import "@fontsource/sora/400.css";
import "@fontsource/sora/600.css";
import "@fontsource/sora";
import "@fontsource/montserrat";
import "@fontsource/montserrat/500.css";
import "@fontsource/caveat";

const AboutPage = () => {

  useEffect(()=> {
    gsap.from(".photoAbout", {duration: 1, rotation: 45, scale: 0.8,  x:400});
  }, []);

  return (
    <Layout pageTitle="about">
      
      <div className="contentAbout" >
        <h2>Who am I ?</h2>
        <div className="about flexH">
          <div className="blockText">
            
            <p>Mexican based in France, currently working as UX / UI Designer at <a className="link" href="https://www.ibm.com/fr-fr" rel="noreferrer" target="_blank">IBM</a> Client Innovation Center.</p>
            <p>In love with Art, pixels, Yoga, and Indie Rock.</p>
            <p><span  className="bigViolet">Designer with +6 years of experience.</span></p>
            <p>I have a Graphic Design background, experience in Motion Design 2D and a technical knowledge in Web and IOS Front End Development ( HTML5, CSS3, JavaScript, React.js & SwiftUI). </p>
            <p>Previously I worked at <a className="link" href="https://enki-home.com/" rel="noreferrer" target="_blank">Enki</a>, the French smart home developed by Leroy Merlin. And for almost five years, at <a className="link" href="https://www.ingroupe.com/" rel="noreferrer" target="_blank">IN Groupe</a>, global leader in identity and security.</p>
            <p className="cercleWord">I’m committed to my work, responsible, creative, <span className="ligne">ethical</span>, I know how to work on a team and I am open to new challenges and opportunities that allow me to continue growing and developing.
            </p>
            <div className='flexH'>
              <div className="boutonAbout">
                <a href="https://drive.google.com/file/d/17eMvfK0tKNiZpxBD6wgBxUbErHnetB-G/view?usp=sharing" rel="noreferrer" target="_blank"><button>View Resumé</button></a>
              </div>
              <div className="boutonAbout">
                <a href="https://www.linkedin.com/in/valdezmayte/" rel="noreferrer" target="_blank"><button>Connect on LinkedIn </button></a>
              </div>
              
            </div>
            
            
          </div>
          <StaticImage
          className="photoAbout"
          alt="Photo of Mayte Valdez"
          src="../images/photoMay.png"
          placeholder="blurred"
          layout="constrained"
          width={550}
          height={580}
          />
        </div>
        
        
        <h3>What  can I do ?</h3>
        <div className="skills">
          <div>
            <StaticImage
            alt="Illustration of Design Thinking"
            src="../images/designThinking.png"
            placeholder="blurred"
            layout="fixed"
            width={170}
            height={170}
            />
            <ul>
              <li>UX Methode</li>
              <li>Design Thinking</li>
              <li>Project Management</li>
            </ul>
          </div>
          <div>
            <StaticImage
            alt="Illustration of visual design"
            src="../images/graphic.png"
            placeholder="blurred"
            layout="fixed"
            width={170}
            height={170}
            />
            <ul>
              <li>Visual Design</li>
              <li>Design Systems</li>
              <li>UI Design</li>
            </ul>
          </div>
          <div>
            <StaticImage
            alt="Illustration of webdesign"
            src="../images/web.png"
            placeholder="blurred"
            layout="fixed"
            width={170}
            height={170}
            />
            <ul>
              <li>Web Design </li>
              <li>Wireframe, User Flows</li>
              <li>Prototyping</li>
            </ul>
          </div>
          <div>
            <StaticImage
            alt="Illustration of Motion Design"
            src="../images/motionDesign.png"
            placeholder="blurred"
            layout="fixed"
            width={170}
            height={170}
            />
            <ul>
              <li>Motion Design 2D</li>
              <li>Animation web</li>
              <li>Animated GIFs</li>
            </ul>
          </div>
        </div>
        
        <h3>My manifest</h3>
        <div className="manifest">
          <StaticImage
          alt="Photo of Mayte Valdez"
          src="../images/pray.png"
          placeholder="blurred"
          layout="constrained"
          width={180}
          height={180}
          />
          <ul>
            <li>Try, Fail, Try again </li>
            <li>Trust your intuition </li>
            <li>Quality over quantity</li>
            <li>Keep learning</li>
            <li>Work is <span className="ligne">lifetime</span></li>
            <li>Celebrate Small Wins</li>
            <li>Stay humble</li>
          </ul>
          
        </div>
        
      </div>
    </Layout>
  )
}

export default AboutPage